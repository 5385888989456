ol,
ul,
dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#disclaimer {
  a {
    text-decoration: underline;
    color: #fff;
  }
}

.dropdown-toggle::after {
  display: none;
}

.collapse:not(.show) {
  display: inherit;
}

.accordion-body {
  padding: 0;
}

.cd .dropdown {
  height: 100%;
}

.noshow {
  display: none;
  visibility: hidden;
}

a:focus {
  background-color: inherit !important;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.display-6 {
  font-size: 18px;
  font-weight: 400;
}

.display-8 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.display-7 {
  font-size: 14px;
  font-weight: 400;
}

.fit-content {
  width: fit-content;
}

a.text-white {
  text-decoration: underline;
}

.cd .form-check:has(.fix-spacing) {
  display: flex;
  justify-content: center;
}
